import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { loadFAQs, exportFAQs } from '../../actions'
import './index.scss'
import moment from 'moment'
import { Tag } from 'antd'
import Title from '../shared/Title'
import SubTitle from '../shared/SubTitle'
import Search from '../shared/Search'
import RangePicker from '../shared/RangePicker'
import Table from '../shared/Table'
import Extract from '../shared/Extract'

export default function FAQs() {
    const [searchKey, setSearchKey] = useState('')
    const [pagination, setPagination] = useState([1, 8])
    const [currentPage, pageSize] = pagination
    const [rangeDate, setRangeDate] = useState()
    const [dateFrom, dateTo] = rangeDate || ['ALL', 'ALL']
    const [exportData, setExportData] = useState([])

    const { isLoading, data: { total, data: faqs } } = useSelector(state => state.faqs)

    const dispatchFAQs = useDispatch();

    useEffect(() => {
        dispatchFAQs(loadFAQs({ currentPage, pageSize, searchKey, dateFrom, dateTo }))
    }, [dispatchFAQs, currentPage, pageSize, searchKey, dateFrom, dateTo])

    const isSearching = (searchKey !== 'ALL' && (searchKey.length >= 1))

    const columns = [
        {
            title: 'Questions',
            dataIndex: 'questions',
            sorter: (a, b) => a.questions.localeCompare(b.questions)
        },
        {
            title: 'Type',
            dataIndex: 'type',
            sorter: (a, b) => a.type.localeCompare(b.type),
            render: (type) => {

                const color = type === 'Main Topic' ? '#D1BCD2' : '#C1E4F7'

                return <Tag color={color}>{type}</Tag>
            }
        },
        {
            title: 'No. of clicks',
            dataIndex: 'no_of_clicks',
            sorter: (a, b) => a.no_of_clicks.localeCompare(b.no_of_clicks)
        }
    ];

    const dataSource = () => faqs?.map(({ id, option: questions, type, clicks }) => ({
        id,
        questions,
        type: type === 1 ? 'Main Topic' : 'Sub-Question',
        no_of_clicks: clicks?.length.toString()
    }))

    const onSearch = () => ({ enter: (key) => setSearchKey(key.currentTarget.value), change: (e) => !e.currentTarget.value && setSearchKey('') })
    const onChangeDate = (ymd) => setRangeDate(ymd?.map(date => moment(date).format('YYYY-MM-DD')))
    const onPaginate = ({ current, pageSize }) => setPagination([current, pageSize])

    useEffect(() => {

        if (faqs) {

            async function fetchExportedData() {

                const based = await exportFAQs({ searchKey, dateFrom, dateTo })

                if (based) {

                    const dataSortByDate = based
                    // .sort((acc, cur) => acc.created_at.localeCompare(cur.created_at))

                    function excelConfig(values) {

                        if (values) {

                            const body = values.map(row => {

                                const xCenter = { alignment: { horizontal: 'center' } }

                                return [
                                    {
                                        value: row?.option, style: xCenter,
                                    },
                                    {
                                        value: row?.type === 1 ? 'Main Topic' : 'Sub-Question', style: xCenter,
                                    },
                                    {
                                        value: row?.clicks.length, style: xCenter,
                                    }
                                ]
                            })

                            function header() {


                                const keys = ['Questions', 'Type', 'No. of Clicks']

                                return keys.map(key => {
                                    return {
                                        value: key, width: { wch: 80 },
                                        style: {
                                            fill: { patternType: "solid", fgColor: { rgb: "06665E" } },
                                            font: { sz: "14", color: { rgb: "FFFFFF" } },
                                            alignment: {
                                                horizontal: "center",
                                            },
                                        },
                                    }
                                })
                            }

                            function emptyData() {

                                const keys = ['', 'No Data', '']

                                function config(key) {
                                    return {
                                        value: key, width: { wpx: 150 },
                                        style: {
                                            fill: { patternType: "solid", fgColor: { rgb: "FFFFFF" } },
                                            font: { sz: "14" },
                                            alignment: {
                                                horizontal: key === 'No' ? 'right' : key === 'Data' ? 'left' : "center",
                                            }
                                        }
                                    }
                                }

                                return values.length ? [] : keys.map(key => config(key))

                            }

                            function dateAndTotal() {

                                return [
                                    [{ value: `Date From:${dateFrom} - Date To: ${dateTo}` }],
                                    [{ value: `Total: ${values.length}` }],
                                    [{ value: '' }],
                                ]
                            }

                            const columns = [

                                {
                                    title: 'ROOTS',
                                    width: { wpx: 120 },
                                    style: {
                                        fill: { patternType: "solid", fgColor: { rgb: "06665E" } },
                                        font: { sz: "20", color: { rgb: "FFFFFF" } },
                                        alignment: {
                                            horizontal: "right",
                                        },
                                    },
                                },
                                {
                                    title: 'OF HEALTH',
                                    width: { wpx: 120 },
                                    style: {
                                        fill: { patternType: "solid", fgColor: { rgb: "06665E" } },
                                        font: { sz: "20", color: { rgb: "FFFFFF" } },
                                    }
                                },
                                {
                                    title: 'FAQS',
                                    width: { wpx: 120 },
                                    style: {
                                        fill: { patternType: "solid", fgColor: { rgb: "06665E" } },
                                        font: { sz: "20", color: { rgb: "FFFFFF" } },
                                    }
                                }
                            ]

                            const data = [...dateAndTotal(), header(), ...body, emptyData()]

                            return [{ columns, data }]
                        }
                    }

                    setExportData(excelConfig(dataSortByDate))
                }

            }

            fetchExportedData()
        }

        return () => setExportData([])

    }, [faqs, currentPage, pageSize, searchKey, dateFrom, dateTo])

    return (
        <section className='faqs' >
            <Title title='FAQs' subTitle={moment().format('LL')} />
            {/* <SubTitle  /> */}
            <Search onSearch={onSearch} />
            <RangePicker attributes={{ onChangeDate, isLoading, rangeDate }} />
            <Extract attributes={{ type: 'faqs', isLoading, exportData }} />
            <Table attributes={{ dataSource, isLoading, isSearching, columns, currentPage, pageSize, total, onPaginate }} />
        </section >
    )
}