import makeRequest from '../utilities/api';
import * as actions from '../actionType'

const request = makeRequest();

export const loadUsers = ({ currentPage, pageSize, searchKey = '', dateFrom = 'ALL', dateTo = 'ALL' }) => async (dispatch) => {

    dispatch({ type: actions.FETCH_USERS_REQUEST })

    try {

        const params = `?page=${currentPage}&search=${searchKey}&pageSize=${pageSize}&dateFrom=${dateFrom}&dateTo=${dateTo}`

        const response = await request.get(`inquirer${params}`);
        const data = await response?.data

        dispatch({ type: actions.FETCH_USERS_SUCCESS, payload: data })

    } catch (error) {

        dispatch({ type: actions.FETCH_USERS_FAILURE, payload: error.message })

    }
}