import React, { memo } from 'react'
import moment from 'moment'
import { DatePicker } from 'antd'
import { BsArrowLeftRight } from "react-icons/bs";
import { IoCalendarClearOutline } from "react-icons/io5";

export default memo(function RangePicker({ attributes: { onChangeDate, isLoading, rangeDate } }) {
    return (
        <DatePicker.RangePicker
            onChange={onChangeDate}
            disabled={isLoading}
            value={rangeDate?.map(row => moment(row))}
            className='range-date'
            format='MM/DD/YYYY'
            allowEmpty
            separator={<BsArrowLeftRight />}
            suffixIcon={<IoCalendarClearOutline />}
        />
    )
})