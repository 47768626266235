import React, { useState, memo } from "react";

import "./index.scss";

import { Drawer, Menu, Avatar } from "antd";
import { Link } from "react-router-dom";
import { BiArrowBack } from "react-icons/bi";
import { RiArrowDropUpLine, RiArrowDropDownLine } from "react-icons/ri";
import { UserOutlined } from '@ant-design/icons';

import makeStorage from "../../../utilities/storage";

export default memo(function DefaultDrawer(props) {

  const newPaths = props.paths.filter((row) => ![''].includes(row.slug))

  const { isDrawerOpen, setDrawer } = props.drawer
  const [isSettingOpen, setSettingOpen] = useState(false)

  const storage = makeStorage();

  const onToggleSetting = () => setSettingOpen(prev => !prev)

  function onLogout() {

    storage.clearSession();
    window.location.href = '/';
  }

  const userName = JSON.parse(storage.getUser())?.user?.first_name

  return (
    <Drawer
      visible={isDrawerOpen}
      onClose={() => setDrawer(false)}
      closeIcon={<BiArrowBack />}
      title={false}
      width={'40vw'}
      placement="top"
      className="drawer"
    >

      <Menu
        mode="inline"
        theme="light"
        className="menu"
        defaultSelectedKeys={[props.location.pathname]}
      >
        {newPaths.map((path) => (
          <Menu.Item key={path.route}>
            <Link data-menu-label={path.slug} to={path.route} onClick={() => setDrawer(false)}>
              {path.slug}
            </Link>
          </Menu.Item>
        ))}
        <div className='user' onClick={onToggleSetting}>
          {isSettingOpen && <Menu className='user-menu'>
            <Menu.Item key="1">
              <div onClick={onLogout}>Logout</div>
            </Menu.Item>
          </Menu>}
          <Avatar size={30} icon={<UserOutlined />} />
          <span className='name'>{userName}</span>
          {isSettingOpen ? <RiArrowDropUpLine className='dropdown-indicator' /> : <RiArrowDropDownLine className='dropdown-indicator' />}
        </div>

      </Menu>
    </Drawer >
  );
})