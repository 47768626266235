import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { exportDashboard, loadDashboard } from '../../actions'
import './index.scss'
import moment from 'moment'

import MostClickedMenu from './components/MostClickedMenu'
import Location from './components/Location'
import Age from './components/Age'
import MostCommonlyUsedContraceptives from './components/MostCommonlyUsedContraceptives'
import ContraceptivesUsersVsNonUsers from './components/ContraceptivesUsersVsNonUsers'

import Title from '../shared/Title'
import SubTitle from '../shared/SubTitle'
import RangePicker from '../shared/RangePicker'
import Extract from '../shared/Extract'

export default function Dashboard() {

    const [rangeDate, setRangeDate] = useState()
    const [dateFrom, dateTo] = rangeDate || ['ALL', 'ALL']
    const [exportData, setExportData] = useState([])
    const [location, setLocation] = useState(0)

    const { isLoading, data: { menu_clicks, locations, age_brackets, contraceptives, user_types } } = useSelector(state => state?.dashboard)

    const dispatchDashboard = useDispatch()

    useEffect(() => {
        dispatchDashboard(loadDashboard({ dateFrom, dateTo, fromPuerto: location }))
    }, [dispatchDashboard, dateFrom, dateTo, location])

    function dataSource() {

        return {
            mostClickedMenuOptions: menu_clicks?.map(({ menu: slug, clicks }) => ({
                slug, count: Number(Number((clicks.length / menu_clicks.map(row => row.clicks.length).reduce((acc, cur) => acc + cur)) * 100).toFixed(0)) || 0
            })),
            location: locations?.map(({ municipality: { citymunDesc: name }, total: count }) => ({ name, count })).splice(0, 10),
            age: age_brackets?.map(({ age_bracket, total }) => ({
                name: age_bracket === 1 ? '19 Years old and Below' : age_bracket === 2 ? '20-29 Years old' : '30 Years old and Above',
                value: Number(Number((total / age_brackets.map(row => row.total).reduce((acc, cur) => acc + cur)) * 100).toFixed(0)) || 0,
                color: age_bracket === 1 ? '#0C665E' : age_bracket === 2 ? '#13AF9F' : '#9AFFF4',
            })),
            mostCommonlyUsedContraceptives: contraceptives?.filter(row => row.contraceptive !== 'Iba pa').concat(contraceptives?.find(row => row.contraceptive === 'Iba pa'))?.map(({ contraceptive: name, usage: value }) => ({
                name, value: value.length, color: name === 'Condom' ? "#0C665E" : name === 'Condom' ? "#119A8D" : name === 'DMPA' ? "#1BDAC3" : name === 'IUD' ? "#1BDAC3" : name === "Implant" ? "#13AF9F" : "#9AFFF4"
            })),
            contraceptivesUsersVsNonUsers: [user_types?.bc_users, user_types?.non_bc_users].map((row, index) => ({ name: row, count: row, color: index === 0 ? '#0E7C72' : '#E0007D' }))
        }
    }

    const onChangeLocation = (e) => setLocation(e.target.value)
    const onChangeDate = (ymd) => setRangeDate(ymd?.map(date => moment(date).format('YYYY-MM-DD')))


    useEffect(() => {
 
        async function fetchExportedData() {

            const { age_brackets, locations, menu_clicks, contraceptives, user_types } = await exportDashboard({ dateFrom, dateTo, fromPuerto: location })

            const based = {
                mostClickedMenuOptions: menu_clicks?.map(({ menu: slug, clicks }) => ({
                    slug,  no_of_clicks: clicks.length, percentage: `${Number(Number((clicks.length / menu_clicks.map(row => row.clicks.length).reduce((acc, cur) => acc + cur)) * 100).toFixed(0)) || 0}%`,
                })),
                location: locations?.map(({ municipality: { citymunDesc: name }, total: count }) => ({ name, count })).splice(0, 10),
                age: age_brackets?.map(({ age_bracket, total }) => ({
                    name: age_bracket === 1 ? '19 Years old and Below' : age_bracket === 2 ? '20-29 Years old' : '30 Years old and Above',
                    percentage: `${Number(Number((total / age_brackets.map(row => row.total).reduce((acc, cur) => acc + cur)) * 100).toFixed(0)) || 0}%`,
                    count: total
                 })),
                mostCommonlyUsedContraceptives: contraceptives?.filter(row => row.contraceptive !== 'Iba pa').concat(contraceptives?.find(row => row.contraceptive === 'Iba pa'))?.map(({ contraceptive: name, usage: value }) => ({
                    name, value: value.length  
                })),
                contraceptivesUsersVsNonUsers: [user_types?.bc_users, user_types?.non_bc_users].map((row, index) => ({ name: row, count: row + '%'}))
            }

            const {mostClickedMenuOptions,age,mostCommonlyUsedContraceptives, location: locReport} = based
  
            function getMCSCPercentage(value) {

                const total = mostCommonlyUsedContraceptives?.map(row => row.value).reduce((acc, cur) => cur + acc)
                return isNaN(Number(((value / total) * 100)).toFixed(0)) ? 0 : Number(((value / total) * 100)).toFixed(0)
            }
           
                function excelConfig(  header,body) {
                    
               
                    const xCenter = { alignment: { horizontal: 'center' } }
               
                        const cvRow =  body.map(row => Object.values(row)).map(row => row.map(value => ({value, style: xCenter})))

                        function thead() {
 
                            return header.map(key =>  ({
                                value: key, width: { wch: 80 },
                                style: {
                                    fill: { patternType: "solid", fgColor: { rgb: "06665E" } },
                                    font: { sz: "14", color: { rgb: "FFFFFF" } },
                                    alignment: {
                                        horizontal: "center",
                                    },
                                }
                            }))
                        }

                        function emptyData() {

                            const keys = ['', '', 'No Data','','' ]

                            function config(key) {
                                return {
                                    value: key, width: { wpx: 150 },
                                    style: {
                                        fill: { patternType: "solid", fgColor: { rgb: "FFFFFF" } },
                                        font: { sz: "14" },
                                        alignment: {
                                            horizontal: key === 'No' ? 'right' : key === 'Data' ? 'left' : "center",
                                        }
                                    }
                                }
                            }

                            return body.length ? [] : keys.map(key => config(key))

                        }

                        function dateAndTotal() {

                            return header.includes('Location') ? [
                                [{ value: `Date From: ${dateFrom} - Date To: ${dateTo}` }],
                                [{ value: location === 1? 'Lives in Puerto Princesa' : 'Lives Outside Puerto Princesa'}],
                                [{ value: `Total: ${body.length}` }],
                                [{ value: '' }],
                            ] : [
                                [{ value: `Date From: ${dateFrom} - Date To: ${dateTo}` }],
                                [{ value: '' }],
                            ] 
                        }

                        const columns = [
                            {
                                title: 'ROOTS OF',
                                width: { wpx: 200 },
                                style: {
                                    fill: { patternType: "solid", fgColor: { rgb: "06665E" } },
                                    font: { sz: "20", color: { rgb: "FFFFFF" } },
                                    alignment: {
                                        horizontal: "right",
                                    }
                                },
                            },
                            {
                                title: 'HEALTH',
                                width: { wpx: 90 },
                                style: {
                                    fill: { patternType: "solid", fgColor: { rgb: "06665E" } },
                                    font: { sz: "20", color: { rgb: "FFFFFF" } },
                                    alignment: {
                                        horizontal: "center",
                                    }
                                },
                            },
                            {
                                title: 'DASHBOARD',
                                width: { wpx: 210 },
                                style: {
                                    fill: { patternType: "solid", fgColor: { rgb: "06665E" } },
                                    font: { sz: "20", color: { rgb: "FFFFFF" } },
                                    alignment: {
                                        horizontal: "left",
                                    }
                                },
                            }
                        ]
                        
                        const data = [
                            ...dateAndTotal(),
                            thead(),
                            ...cvRow,
                            emptyData()
                        ]
                        return [{ columns, data }]
                }

                const toExportData =  { config: [ {
                    type: 'Most Clicked Menu Options',
                    data: excelConfig(
                    ['Type', 'No. of clicks','Percentage'] ,
                    mostClickedMenuOptions
                    )
                },
                {   
                    type: 'Location',
                    data: excelConfig(
                    ['Location', 'Count', ''] ,
                    locReport
                    )
                },
                {
                    type: 'Age',
                    data: excelConfig(
                    ['Age', 'Percentage','Count'] ,
                    age
                    )
                },
                {
                    type: 'Most Commonly Used Contraceptives',
                    data: excelConfig(
                    ['Type', 'Percentage','Count'] ,
                    mostCommonlyUsedContraceptives?.map(row => ({ name: row.name , percentage:  getMCSCPercentage(row.value) + '%' , count: row.value}))
                    )
                }
                ]  }
                setExportData(  toExportData  )
            }
    
        fetchExportedData()

        return () => setExportData([])

    }, [dateFrom, dateTo, location])


    return (
        <section className='dashboard' >
            <Title title='Dashboard' subTitle={moment().format('LL')}/>
            <RangePicker attributes={{ onChangeDate, isLoading, rangeDate }} />
            <Extract attributes={{ type: 'dashboard', isLoading, exportData }} />
            <MostClickedMenu data={dataSource().mostClickedMenuOptions} />
            <Location attributes={{ onChangeLocation, location, data: dataSource().location }} />
            <Age data={dataSource().age} />
            <MostCommonlyUsedContraceptives data={dataSource().mostCommonlyUsedContraceptives} />
            <ContraceptivesUsersVsNonUsers data={dataSource().contraceptivesUsersVsNonUsers} />
        </section >
    )
}
