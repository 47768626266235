import makeRequest from '../../utilities/api';

const request = makeRequest();

export const exportFAQs = async ({ searchKey = '', dateFrom = 'ALL', dateTo = 'ALL' }) => {

    try {
        const params = `?search=${searchKey}&dateFrom=${dateFrom}&dateTo=${dateTo}`

        const response = await request.get(`option-clicks/export${params}`);
        const data = await response?.data

        return data


    } catch (error) {

        throw Error(error)
    }
}