import makeRequest from '../utilities/api';
import * as actions from '../actionType'

const request = makeRequest();

export const loadFAQs = ({ currentPage, pageSize, searchKey = '', dateFrom = '', dateTo = '' }) => async (dispatch) => {

    dispatch({ type: actions.FETCH_FAQS_REQUEST })

    try {

        const params = `?page=${currentPage}&search=${searchKey}&pageSize=${pageSize}&dateFrom=${dateFrom}&dateTo=${dateTo}`

        const response = await request.get(`option-click${params}`);
        const data = await response?.data

        dispatch({ type: actions.FETCH_FAQS_SUCCESS, payload: data })

    } catch (error) {

        dispatch({ type: actions.FETCH_FAQS_FAILURE, payload: error.message })

    }
}