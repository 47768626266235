import React, { memo } from 'react'
import { Radio, Empty } from 'antd'
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, ResponsiveContainer } from "recharts";
export default memo(function Location({ attributes: { onChangeLocation, location, data } }) {

    return (
        <div className='location'>
            <h4>Location</h4>
            <Radio.Group options={[
                { label: 'Lives in Puerto Princesa', value: 1 },
                { label: 'Lives Outside Puerto Princesa', value: 0 },
            ]}
                onChange={onChangeLocation}
                value={location}
                optionType="button"
                buttonStyle="solid"
                className='action'
            />
            <div className='wrapped-chart' style={{ width: '100%', height: '80%' }}>
                {!data?.length ? <Empty className='dashboard-partof-empty' image={Empty.PRESENTED_IMAGE_SIMPLE} />

                    : <>
                        <span style={{ visibility: 'hidden' }}>.</span>
                        <ResponsiveContainer>
                            <BarChart
                                data={data}
                                margin={{
                                    top: 20,

                                    // right: -70,
                                    // left: -110,
                                    bottom: 40
                                }}
                            >
                                <CartesianGrid stroke='#f2f2f2'
                                    vertical={false}
                                    axisLine={false} />
                                <XAxis
                                    tick={{ angle: -451 }}
                                    textAnchor='end'
                                    axisLine={false}
                                    tickLine={false}
                                    interval={0}
                                    dataKey="name"
                                    dx={-5}
                                    dy={20}
                                />
                                <YAxis axisLine={false} tickLine={false} />
                                <Bar dataKey="count" fill="#0A9A8D" barSize={20} />
                            </BarChart>
                        </ResponsiveContainer>
                    </>

                }

            </div>
        </div>
    )
})
