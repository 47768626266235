import React, { memo } from 'react'

import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Cell, ResponsiveContainer } from "recharts";

export default memo(function ContraceptivesUsersVsNonUsers({ data }) {
    return (
        <div className='c-users'>
            <h4>Contraceptives Users vs. Non-Users</h4>

            <div className='legends'>
                <span>Users</span>
                <span>Non-Users</span>
            </div>

            <div className='wrapped-chart' style={{ width: '100%', height: '70%' }}>
                <ResponsiveContainer>
                    <BarChart data={data} margin={{ top: 5, right: 30, left: 20, bottom: 5 }} >
                        <CartesianGrid stroke='#f2f2f2' vertical={false} axisLine={false} />
                        <XAxis axisLine={false} tickLine={false} interval={0} dataKey="name" />
                        <YAxis axisLine={false} tickLine={false} />
                        <Bar dataKey="count" barSize={30}>
                            {data.map((row, index) => (<Cell key={`cell-${index}`} fill={row.color} />))}
                        </Bar>
                    </BarChart>
                </ResponsiveContainer>
            </div>
        </div>
    )
})
