import React from 'react'
import { Button } from 'antd'
import moment from 'moment'
import { BsDownload } from "react-icons/bs";
import ReactExport from "react-data-export";

import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

export default function Extract({ attributes: { type, isLoading, exportData } }) {

    const hasManySheet = !Array.isArray(exportData)

    return (

        <Button
            disabled={isLoading}
            className={`export ${type}-extract`} size='large'>
            <ExcelFile
                filename={`${type}_${moment().format('MM-DD-YY')} `}
                element={
                    <div className='extract-excelfile-element'>{isLoading ? <Spin indicator={<LoadingOutlined style={{ fontSize: 14 }} spin />} /> : <BsDownload />} Extract</div>
                }>
                {exportData && 
                ( 
                    hasManySheet 
                    ? 
                    exportData?.config?.map((row,key) =>  (<ExcelSheet key={key} dataSet={row.data} name={row.type} />))
                 : 
                 <ExcelSheet dataSet={exportData} name={`${type}_${moment().format('MM-DD-YY')} `} />
                 )
                 }
            </ExcelFile>
        </Button>
    )
}
