import React, { memo } from 'react'
import { Input } from 'antd'
import { FiSearch } from "react-icons/fi";

export default memo(function Search({ onSearch }) {

    const { enter, change } = onSearch()

    return (
        <Input
            onPressEnter={enter}
            onChange={change}
            placeholder="Search"
            className='search'
            prefix={<FiSearch />}
        />
    )
})