import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { loadUsers, exportUsers } from '../../actions'
import './index.scss'
import moment from 'moment'

import Title from '../shared/Title'
import SubTitle from '../shared/SubTitle'
import Search from '../shared/Search'
import RangePicker from '../shared/RangePicker'
import Table from '../shared/Table'
import Extract from '../shared/Extract'

export default function Users() {
    const [searchKey, setSearchKey] = useState('')
    const [pagination, setPagination] = useState([1, 8])
    const [currentPage, pageSize] = pagination
    const [rangeDate, setRangeDate] = useState()
    const [dateFrom, dateTo] = rangeDate || ['ALL', 'ALL']
    const [exportData, setExportData] = useState([])

    const { isLoading, data: { total, data: users } } = useSelector(state => state.users)

    const dispatchUsers = useDispatch()

    useEffect(() => {
        dispatchUsers(loadUsers({ currentPage, pageSize, searchKey, dateFrom, dateTo }))
    }, [dispatchUsers, currentPage, pageSize, searchKey, dateFrom, dateTo])

    const isSearching = (searchKey !== 'ALL' && (searchKey.length >= 1))

    const columns = [
        {
            title: 'Full Name',
            dataIndex: 'full_name',
            sorter: (a, b) => a.full_name.localeCompare(b.full_name)
        },
        {
            title: 'Date',
            dataIndex: 'created_at',
            sorter: (a, b) => a.created_at.localeCompare(b.created_at),
            render: (a) => moment(a).format('MM/DD/YYYY')
        },
        {
            title: 'Age',
            dataIndex: 'age_bracket',
            sorter: (a, b) => a.age_bracket.localeCompare(b.age_bracket),

        },
        {
            title: 'Contraceptive Used',
            dataIndex: 'contraceptive_used',
            sorter: (a, b) => a.contraceptive_used.localeCompare(b.contraceptive_used),
        },
        {
            title: 'Location',
            dataIndex: 'location',
            sorter: (a, b) => a.location.localeCompare(b.location)
        }
    ];

    const dataSource = () => users?.map(({ id, full_name, created_at, age_bracket, municipality, contraceptive }) => ({
        id, created_at, full_name, age_bracket: age_bracket === 1 ? '19 below' : age_bracket === 2 ? '20-29' : '30 and Above',
        location: `${municipality?.citymunDesc}`, contraceptive_used: contraceptive?.contraceptive || 'None'
    }))

    const onSearch = () => ({ enter: (key) => setSearchKey(key.currentTarget.value), change: (e) => !e.currentTarget.value && setSearchKey('') })
    const onChangeDate = (ymd) => setRangeDate(ymd?.map(date => moment(date).format('YYYY-MM-DD')))
    const onPaginate = ({ current, pageSize }) => setPagination([current, pageSize])

    useEffect(() => {

        if (users) {

            async function fetchExportedData() {

                const based = await exportUsers({ searchKey, dateFrom, dateTo })

                if (based) {

                    const dataSortByDate = based
                    // .sort((acc, cur) => acc.created_at.localeCompare(cur.created_at))

                    function excelConfig(values) {

                        if (values) {

                            const body = values.map(row => {

                                const xCenter = { alignment: { horizontal: 'center' } }

                                return [
                                    {
                                        value: row?.full_name, style: xCenter,
                                    },
                                    {
                                        value: row?.age_bracket === 1 ? '19 Years old and Below'
                                            : row?.age_bracket === 2
                                                ? '20-29 Years old'
                                                : '30 Years old and Above', style: xCenter,
                                    },
                                    {
                                        value: row?.contraceptive?.contraceptive || 'None', style: xCenter,
                                    },
                                    {
                                        value: row?.city, style: xCenter,
                                    },
                                    {
                                        value: row?.municipality?.province?.provDesc, style: xCenter,
                                    },
                                    {
                                        value: row?.municipality?.province?.region?.regDesc, style: xCenter,
                                    }
                                ]
                            })

                            function header() {


                                const keys = ['Name', 'Age', 'Contraceptive Used', 'City/Municipality', 'Province', 'Region']

                                return keys.map(key => {
                                    return {
                                        value: key, width: { wch: 80 },
                                        style: {
                                            fill: { patternType: "solid", fgColor: { rgb: "06665E" } },
                                            font: { sz: "14", color: { rgb: "FFFFFF" } },
                                            alignment: {
                                                horizontal: "center",
                                            },
                                        },
                                    }
                                })
                            }

                            function emptyData() {

                                const keys = ['', '', '', 'No Data', '', '', '']

                                function config(key) {
                                    return {
                                        value: key, width: { wpx: 150 },
                                        style: {
                                            fill: { patternType: "solid", fgColor: { rgb: "FFFFFF" } },
                                            font: { sz: "14" },
                                            alignment: {
                                                horizontal: key === 'No' ? 'right' : key === 'Data' ? 'left' : "center",
                                            }
                                        }
                                    }
                                }

                                return values.length ? [] : keys.map(key => config(key))

                            }

                            function dateAndTotal() {

                                return [
                                    [{ value: `Date From:${dateFrom} - Date To: ${dateTo}` }],
                                    [{ value: `Total: ${values.length}` }],
                                    [{ value: '' }],
                                ]
                            }

                            const columns = [
                                {
                                    title: '',
                                    width: { wpx: 150 },
                                    style: {
                                        fill: { patternType: "solid", fgColor: { rgb: "06665E" } },
                                    },
                                },
                                {
                                    title: '',
                                    width: { wpx: 150 },
                                    style: {
                                        fill: { patternType: "solid", fgColor: { rgb: "06665E" } },
                                    },
                                },
                                {
                                    title: 'ROOTS OF',
                                    width: { wpx: 200 },
                                    style: {
                                        fill: { patternType: "solid", fgColor: { rgb: "06665E" } },
                                        font: { sz: "20", color: { rgb: "FFFFFF" } },
                                        alignment: {
                                            horizontal: "right",
                                        }
                                    },
                                },
                                {
                                    title: 'HEALTH USERS',
                                    width: { wpx: 200 },
                                    style: {
                                        fill: { patternType: "solid", fgColor: { rgb: "06665E" } },
                                        font: { sz: "20", color: { rgb: "FFFFFF" } },
                                        // alignment: {
                                        //     horizontal: "right",
                                        // },
                                    },
                                },
                                {
                                    title: "",
                                    width: { wpx: 150 },
                                    style: {
                                        fill: { patternType: "solid", fgColor: { rgb: "06665E" } },
                                    },
                                },
                                {
                                    title: '',
                                    width: { wpx: 150 },
                                    style: {
                                        fill: { patternType: "solid", fgColor: { rgb: "06665E" } },
                                    },
                                },
                                {
                                    title: '',
                                    width: { wpx: 150 },
                                    style: {
                                        fill: { patternType: "solid", fgColor: { rgb: "06665E" } },
                                    },
                                },
                            ]

                            const data = [...dateAndTotal(), header(), ...body, emptyData()]

                            return [{ columns, data }]
                        }
                    }

                    setExportData(excelConfig(dataSortByDate))
                }

            }

            fetchExportedData()
        }

        return () => setExportData([])

    }, [users, searchKey, dateFrom, dateTo])

    return (
        <section className='users'>
            <Title title='Users' subTitle={moment().format('LL')}  />
            {/* <SubTitle /> */}
            <Search onSearch={onSearch} />
            <RangePicker attributes={{ onChangeDate, isLoading, rangeDate }} />
            <Extract attributes={{ type: 'users', isLoading, exportData }} />
            <Table attributes={{ dataSource, isLoading, isSearching, columns, currentPage, pageSize, total, onPaginate }} />
        </section>
    )
}

