import Dashboard from './components/Dashboard'
import Users from './components/Users'
import Appointments from './components/Appointments'
import FAQs from './components/FAQs'


const paths = [
    {
        exact: true,
        slug: "Dashboard",
        route: "/",
        component: Dashboard,
    },
    {
        exact: true,
        slug: "Users",
        route: "/users",
        component: Users,
    },
    {
        exact: true,
        slug: "Services - Appointment",
        route: "/appointments",
        component: Appointments,
    },
    {
        exact: true,
        slug: "FAQs",
        route: "/faqs",
        component: FAQs,
    }
];

export default paths