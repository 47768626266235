import { combineReducers } from 'redux'

import auth from './auth'
import users from './users'
import appointments from './appointments'
import faqs from './faqs'


import dashboard from './dashboard'

const rootReducer = combineReducers({
    auth,
    users,
    appointments,
    faqs,

    dashboard
})

export default rootReducer
