import React, { useState, memo } from "react"
import { Route, Switch, withRouter, Redirect } from "react-router-dom"
import { useSelector } from "react-redux"
import paths from '../paths'

import { Grid } from "antd"

import { CgMenuRightAlt } from 'react-icons/cg';

import Login from './Login'
import Appointment from './WebView/Appointment'

import Sider from './shared/Sider'
import Drawer from './shared/Drawer'
import Error404 from './shared/Error404'

import './App.scss'
import 'antd/dist/antd.css'

export default memo(withRouter(function App(props) {

  const auth = useSelector(state => state.auth)
  const { isLoggedIn } = auth;

  const [isDrawerOpen, setDrawer] = useState(false)

  const { useBreakpoint } = Grid

  const breakpoint = useBreakpoint()
  const isXs = breakpoint.xs



  if (isLoggedIn) {

    return (
      <section className='layout'>
        <Drawer {...props} paths={paths} drawer={{ isDrawerOpen, setDrawer }} />
        <Sider {...props} paths={paths} />
        <main>
          {isXs && <header>
            <div className='logo'></div>  <CgMenuRightAlt onClick={() => setDrawer(prev => !prev)} />
          </header>}

          <Switch>
            {window.scrollTo(0, 0)}
            {paths.map((path) => <Route key={path.slug} exact={path.exact} path={path.route} component={path.component} />)}
            <Route component={Error404} />
            <Redirect to="/404" />
          </Switch>

        </main>
      </section>
    );

  } else {
    return (
      <Switch>
        {window.scrollTo(0, 0)}
        <Route
          path="/appointment/:psid/:service_id"
          component={Appointment}
          exact
        />
        <Route path="/" component={Login} />
      </Switch>
    )

  }


}));
