import React from "react";
import { Button, Result } from "antd";

export default function Error404(props) {

  return (
    <section>
      <Result
        className='page fof'
        status="404"
        title="404 page not found"
        subTitle="We are sorry but the page you are looking for does not exist."
        extra={<Button type="primary" className='btn-primary' onClick={() => props.history.goBack()}>Back</Button>}
      />
    </section>

  );
}
