import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { loadAppointments, exportAppointments } from '../../actions'
import './index.scss'
import moment from 'moment'

import Title from '../shared/Title'
import SubTitle from '../shared/SubTitle'
import Search from '../shared/Search'
import RangePicker from '../shared/RangePicker'
import Table from '../shared/Table'
import Extract from '../shared/Extract'

export default function Appointments() {
    const [searchKey, setSearchKey] = useState('')
    const [pagination, setPagination] = useState([1, 8])
    const [currentPage, pageSize] = pagination
    const [rangeDate, setRangeDate] = useState()
    const [dateFrom, dateTo] = rangeDate || ['ALL', 'ALL']
    const [exportData, setExportData] = useState([])

    const { isLoading, data: { total, data: appointments } } = useSelector(state => state.appointments)

    const dispatchAppointments = useDispatch();

    useEffect(() => {
        dispatchAppointments(loadAppointments({ currentPage, pageSize, searchKey, dateFrom, dateTo }))
    }, [dispatchAppointments, currentPage, pageSize, searchKey, dateFrom, dateTo])

    const isSearching = (searchKey !== 'ALL' && (searchKey.length >= 1))

    const columns = [
        {
            title: 'Full Name',
            dataIndex: 'full_name',
            sorter: (a, b) => a.full_name.localeCompare(b.full_name)
        },
        {
            title: 'Mobile Number',
            dataIndex: 'mobile_number',
            sorter: (a, b) => a.mobile_number.localeCompare(b.mobile_number)
        },
        {
            title: 'Appointment Schedule',
            dataIndex: 'date',
            sorter: (a, b) => a.date.localeCompare(b.date),
        },
        {
            title: 'Services Availed',
            dataIndex: 'service',
            sorter: (a, b) => a.service.localeCompare(b.service),

        },
        {
            title: 'Clinic Branch',
            dataIndex: 'branch',
            sorter: (a, b) => a.branch.localeCompare(b.branch),
        }
    ];

    const dataSource = () => appointments?.map(({ id, inquirer, service, branch, time, date, mobile_number }) => ({
        id,
        full_name: inquirer?.full_name,
        mobile_number: (() => {
            if (mobile_number.length === 11) {

                return mobile_number.split("").map((row, index) => {

                    if ([3, 6].includes(index)) return row + ' '
                    return row

                }).toString().replaceAll(',', '')

            }
            return mobile_number
        })(),
        date : `${moment(date).format('MM/DD/YYYY')} - ${time}`,
         service: service[0]?.service,
        branch: branch === 1 ? 'San Pedro' : 'Rizal'
    }))

    const onSearch = () => ({ enter: (key) => setSearchKey(key.currentTarget.value), change: (e) => !e.currentTarget.value && setSearchKey('') })
    const onChangeDate = (ymd) => setRangeDate(ymd?.map(date => moment(date).format('YYYY-MM-DD')))
    const onPaginate = ({ current, pageSize }) => setPagination([current, pageSize])

    useEffect(() => {

        if (appointments) {

            async function fetchExportedData() {

                const based = await exportAppointments({ searchKey, dateFrom, dateTo })

                if (based) {

                    const dataSortByDate = based
                    // .sort((acc, cur) => acc.created_at.localeCompare(cur.created_at))

                    function excelConfig(values) {

                        if (values) {

                            const body = values.map(row => {

                                const xCenter = { alignment: { horizontal: 'center' } }

                                return [
                                    {
                                        value: row?.inquirer?.full_name, style: xCenter,
                                    },
                                    {
                                        value: row?.mobile_number, style: xCenter,
                                    },
                                    {
                                        value: moment(row?.date).format('MM/DD/YYYY'), style: xCenter,
                                    },
                                    {
                                        value: row?.time, style: xCenter,
                                    },
                                    {
                                        value: row?.service[0].service || 'None', style: xCenter,
                                    },
                                    {
                                        value: row?.branch === 1 ? 'San Pedro' : 'Rizal', style: xCenter,
                                    }
                                ]
                            })

                            function header() {

                                const keys = ['Full Name', 'Mobile Number', 'Date', 'Time', 'Services Availed', 'Clinic Branch']

                                return keys.map(key => {
                                    return {
                                        value: key, width: { wch: 80 },
                                        style: {
                                            fill: { patternType: "solid", fgColor: { rgb: "06665E" } },
                                            font: { sz: "14", color: { rgb: "FFFFFF" } },
                                            alignment: {
                                                horizontal: "center",
                                            },
                                        },
                                    }
                                })
                            }

                            function emptyData() {

                                const keys = ['', '', '', 'No Data', '', '', '']

                                function config(key) {
                                    return {
                                        value: key, width: { wpx: 150 },
                                        style: {
                                            fill: { patternType: "solid", fgColor: { rgb: "FFFFFF" } },
                                            font: { sz: "14" },
                                            alignment: {
                                                horizontal: key === 'No' ? 'right' : key === 'Data' ? 'left' : "center",
                                            }
                                        }
                                    }
                                }

                                return values.length ? [] : keys.map(key => config(key))

                            }

                            function dateAndTotal() {

                                return [
                                    [{ value: `Date From:${dateFrom} - Date To: ${dateTo}` }],
                                    [{ value: `Total: ${values.length}` }],
                                    [{ value: '' }],
                                ]
                            }

                            const columns = [
                                {
                                    title: '',
                                    width: { wpx: 150 },
                                    style: {
                                        fill: { patternType: "solid", fgColor: { rgb: "06665E" } },
                                    },
                                },
                                {
                                    title: '',
                                    width: { wpx: 150 },
                                    style: {
                                        fill: { patternType: "solid", fgColor: { rgb: "06665E" } },
                                    },
                                },
                                {
                                    title: 'ROOTS OF HEALTH',
                                    width: { wpx: 210 },
                                    style: {
                                        fill: { patternType: "solid", fgColor: { rgb: "06665E" } },
                                        font: { sz: "20", color: { rgb: "FFFFFF" } },
                                        alignment: {
                                            horizontal: "right",
                                        }
                                    },
                                },
                                {
                                    title: 'APPOINTMENTS',
                                    width: { wpx: 210 },
                                    style: {
                                        fill: { patternType: "solid", fgColor: { rgb: "06665E" } },
                                        font: { sz: "20", color: { rgb: "FFFFFF" } },
                                        // alignment: {
                                        //     horizontal: "right",
                                        // },
                                    },
                                },
                                {
                                    title: "",
                                    width: { wpx: 150 },
                                    style: {
                                        fill: { patternType: "solid", fgColor: { rgb: "06665E" } },
                                    },
                                },
                                {
                                    title: '',
                                    width: { wpx: 150 },
                                    style: {
                                        fill: { patternType: "solid", fgColor: { rgb: "06665E" } },
                                    },
                                },
                                {
                                    title: '',
                                    width: { wpx: 150 },
                                    style: {
                                        fill: { patternType: "solid", fgColor: { rgb: "06665E" } },
                                    },
                                },
                            ]

                            const data = [...dateAndTotal(), header(), ...body, emptyData()]

                            return [{ columns, data }]
                        }
                    }

                    setExportData(excelConfig(dataSortByDate))
                }

            }

            fetchExportedData()
        }

        return () => setExportData([])

    }, [appointments, searchKey, dateFrom, dateTo])


    return (
        <section className='appointments' >
            <Title title='Services - Appointment' subTitle={moment().format('LL')}  />
            {/* <SubTitle subTitle={moment().format('LL')} /> */}
            <Search onSearch={onSearch} />
            <RangePicker attributes={{ onChangeDate, isLoading, rangeDate }} />
            <Extract attributes={{ type: 'appointments', isLoading, exportData }} />
            <Table attributes={{ dataSource, isLoading, isSearching, columns, currentPage, pageSize, total, onPaginate }} />
        </section >
    )
}