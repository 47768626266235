import * as actions from '../actionType'

const initialState = {
    isLoading: false,
    data: [],
    error: '',
}

export default function users(state = initialState, action) {
    switch (action.type) {
        case actions.FETCH_USERS_REQUEST:
            return {
                ...state,
                isLoading: true,
            }
        case actions.FETCH_USERS_SUCCESS:
            return {
                isLoading: false,
                data: action.payload,
                error: ''
            }
        case actions.FETCH_USERS_FAILURE:
            return {
                isLoading: false,
                data: [],
                error: action.payload
            }
        default: return state;
    }
}
