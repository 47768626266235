import React, { memo } from 'react'
import { Empty, Table, Grid } from 'antd'

export default memo(function DefaultTable({ attributes }) {

    const { dataSource, isLoading, isSearching, columns, currentPage, pageSize, total, onPaginate } = attributes

    const { useBreakpoint } = Grid

    const isXs = useBreakpoint().xs

    return (
        <Table
            rowKey={obj => obj.id}
            loading={isLoading}
            columns={columns}
            dataSource={dataSource()}
            pagination={{ current: currentPage, pageSize, total }}
            scroll={isXs && { x: 'max-content' }}
            onChange={onPaginate}
            className='table'
            locale={{
                emptyText: (isSearching
                    ?
                    <>
                        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description='No Results Found' />
                    </>
                    : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />)
            }}
        />
    )
})