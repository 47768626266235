import React, { useState, memo } from "react";
import { Menu } from "antd";
import { Link } from "react-router-dom";
import "./index.scss";
import { RiArrowDropUpLine, RiArrowDropDownLine } from "react-icons/ri";
import makeStorage from "../../../utilities/storage";

export default memo(function Sider(props) {

  const newPaths = props.paths.filter((row) => ![''].includes(row.slug))

  const [isSettingOpen, setSettingOpen] = useState(false)

  const storage = makeStorage()

  function onLogout() {

    storage.clearSession();
    window.location.href = '/'
    setSettingOpen(false)
  }

  const onToggleSetting = () => setSettingOpen(prev => !prev)

  const userName = (() => {
    const { first_name, last_name } = JSON.parse(storage.getUser())?.user
    return `${first_name} ${last_name}`
  })()

  return (
    <aside>
      <div className='logo'></div>
      <Menu
        mode="inline"
        theme="light"
        className="menu"
        defaultSelectedKeys={[props.location.pathname]}
      >
        {newPaths.map((path) => (
          <Menu.Item key={path.route}>
            <Link data-menu-label={path.slug} to={path.route}>
              {path.slug}
            </Link>
          </Menu.Item>
        ))}
      </Menu>

      <div className='user' onClick={onToggleSetting}>
        {isSettingOpen && <Menu className='user-menu'>
          <Menu.Item key="1">
            <div onClick={onLogout}>Logout</div>
          </Menu.Item>
        </Menu>}
        <span className='name'>{userName}</span>
        {isSettingOpen ? <RiArrowDropUpLine className='dropdown-indicator' /> : <RiArrowDropDownLine className='dropdown-indicator' />}
      </div>
    </aside>
  )
});
