import makeRequest from '../utilities/api';
import * as actions from '../actionType'

const request = makeRequest();

export const loadDashboard = ({ dateFrom = 'ALL', dateTo = 'ALL', fromPuerto = 1 }) => async (dispatch) => {

    dispatch({ type: actions.FETCH_DASHBOARD_REQUEST })

    try {

        const params = `?dateFrom=${dateFrom}&dateTo=${dateTo}&fromPuerto=${fromPuerto}`

        const response = await request.get(`dashboard${params}`);
        const data = await response?.data

        dispatch({ type: actions.FETCH_DASHBOARD_SUCCESS, payload: data })

    } catch (error) {

        dispatch({ type: actions.FETCH_DASHBOARD_FAILURE, payload: error.message })

    }
}