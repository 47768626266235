import makeRequest from '../../utilities/api';

const request = makeRequest();

export const exportDashboard = async ({ dateFrom = 'ALL', dateTo = 'ALL', fromPuerto = 1 }) => {


    try {

        const params = `?dateFrom=${dateFrom}&dateTo=${dateTo}&fromPuerto=${fromPuerto}`

        const response = await request.get(`dashboard/export${params}`);
        const data = await response?.data

        return data


    } catch (error) {

        throw Error(error)
    }
}