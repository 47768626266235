import React, { memo } from 'react'
import { Progress, Empty } from 'antd'
import { PieChart, Pie, Cell, ResponsiveContainer, Legend, Sector } from "recharts";

export default memo(function MostCommonlyUsedContraceptives({ data }) {

    const total = data?.map(row => row.value).reduce((acc, cur) => cur + acc)

    const max = data && Math.max(...data?.map(row => row.value))
    const highestPercentage = Number(((max / total) * 100)).toFixed(0)

    function getMCSCPercentage(value) {
        return isNaN(Number(((value / total) * 100)).toFixed(0)) ? 0 : Number(((value / total) * 100)).toFixed(0)
    }
 
    const highestCommonlyUsedColor = data && [...data].sort((acc, cur) => cur.value - acc.value).shift()?.color

    const renderActiveShape = ({ cx, cy, innerRadius, outerRadius, startAngle, endAngle, percent }) => {

        return (
            <g>
                <text x={cx} y={cy} dy={8} className='highest-percentage' textAnchor="middle" fill="#333">
                    {/* {(percent * 100).toFixed(0)} */}
                    {highestPercentage}
                </text>
                <text x={cx} y={cy} dy={8} dx={25} className='highest-percentage-indicator' textAnchor="middle" fill="#333">
                    %
                </text>
                <Sector
                    cx={cx}
                    cy={cy}
                    innerRadius={innerRadius}
                    outerRadius={outerRadius}
                    startAngle={startAngle}
                    endAngle={endAngle}
                    fill={highestCommonlyUsedColor}
                />
                <Sector
                    cx={cx}
                    cy={cy}
                    startAngle={startAngle}
                    endAngle={endAngle}
                    innerRadius={outerRadius + 0}
                    outerRadius={outerRadius + 10}
                    fill={highestCommonlyUsedColor}
                />
            </g>
        );
    };

    return (
        <div className='mcuc'>
            <h4>Most Commonly Used Contraceptives</h4>
            {data?.every(row => row.value === 0) && <Empty className='dashboard-partof-empty' image={Empty.PRESENTED_IMAGE_SIMPLE} />}
            <div className='wrapped-chart' style={{ width: '100%', height: '100%' }}>

                <ResponsiveContainer>
                    <PieChart >
                        <Pie
                            strokeOpacity={0}
                            activeIndex={0}
                            isAnimationActive={true}
                            activeShape={renderActiveShape}
                            data={data}
                            cx={120}
                            cy={100}
                            innerRadius={70}
                            outerRadius={80}
                            dataKey="value"
                            labelLine={false}
                        >
                            {data?.map((row, index) => (<Cell key={`cell-${index}`} fill={row.color} />))}
                        </Pie>
                        <Legend verticalAlign="bottom" />
                    </PieChart>
                </ResponsiveContainer>

            </div>
            <div className='status'>
                <h5>Status</h5>
                <div className='progress'>
                    {data?.map(row => (
                        <div key={row.name}>
                            <span className='name'>{row.name}</span>
                            <Progress
                                percent={getMCSCPercentage(row.value)}
                                strokeColor={row.color}
                                format={(percent) => { return percent === 100 ? percent + '%' : percent + '%' }}
                            />
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
})
