import makeRequest from '../utilities/api';
import * as actions from '../actionType'

const request = makeRequest();

export const loadAppointments = ({ currentPage, pageSize, searchKey = 'ALL', dateFrom = 'ALL', dateTo = 'ALL' }) => async (dispatch) => {

    dispatch({ type: actions.FETCH_APPOINTMENTS_REQUEST })

    try {

        const params = `?page=${currentPage}&search=${searchKey}&pageSize=${pageSize}&dateFrom=${dateFrom}&dateTo=${dateTo}`

        const response = await request.get(`appointment${params}`);
        const data = await response?.data

        dispatch({ type: actions.FETCH_APPOINTMENTS_SUCCESS, payload: data })

    } catch (error) {

        dispatch({ type: actions.FETCH_APPOINTMENTS_FAILURE, payload: error.message })

    }
}