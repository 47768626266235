import React, { memo } from 'react'

export default memo(function MostClickedMenu({ data }) {
    return (
        <div className='most-clicked'>
            <h4>Most Clicked Menu Options</h4>
            <div className='totals'>
                {data?.map(total => (
                    <section key={total.slug}>
                        <span className='slug'>{total.slug}</span>
                        <span className='count'>({total.count.toLocaleString()}%)</span>
                    </section>
                ))}
            </div>
        </div>
    )
})