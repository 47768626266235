import React, { useState } from 'react'
import './index.scss'
import moment from 'moment'
import axios from 'axios'
import { Form, Input, Button, DatePicker, TimePicker, Radio, message } from "antd";

export default function Appointment(props) {
    const [branch, selectBranch] = useState(1)

    const [form] = Form.useForm()

    const onSubmit = async (values) => {

        const params = {
            ...values,
            date: moment(values.date).format('YYYY-MM-DD hh:mm:ss'),
            time: moment(values.time).format('hh:mm A'),
        }


        try {

            const headers = {
                Authorization: `Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiIxIiwianRpIjoiYmRhNTRhY2YwNjY2ODZkOWIzMGUzNzcyMzFiNzY3M2E2NzY3NzdkZjhlMTFhNjk0MDI5MDE1NDY0YzFjNGMyMDFmMDlmYWFmY2IwZjMwYTMiLCJpYXQiOjE2MjQ5MzQ0NTAuMzUyNTk1LCJuYmYiOjE2MjQ5MzQ0NTAuMzUyNTk4LCJleHAiOjE2NTY0NzA0NTAuMzQ1MzIxLCJzdWIiOiIxIiwic2NvcGVzIjpbXX0.lLrp-Oc59MF9fJTgZYolBVZd_1KjWT9biHHQEA6l78oE0TDzILeJNzKWbG2i2pbUpLBTaUIgVqJkB1SOoU9KA5msqNGGYRVyaGwJDSGTb4WWd07s5GTC3S1O8etzKImUcvoKdkesEGvt2ZzQBDJdilzKoPM8-bcPZbbqhskt1zSPLzIv0Nge5AJV37B3H-lM5pjLyN37coD1Lv7L-lQOzK-Gs0awLp3IdnMC8TbPecrCjCjVC7bSdL6lNEJ-u00wbr4KN67p9v4i5KmUBmiBesGfM8uXmCcNe-pR_VKlRHuJS5i0vTi-hu-4OYiCNNJdl7ykSbAqmdAaV_HhICOEN3vriRJAYiPbjzUEh477fLphX9iaUM2u7MAtzrszSP0_4g9XNt1N9UR2dYnTJ5NzADRtQMbte4tmoccGa_DlnpTji1hwHrNDo0lWav7c75RcXiQRwH_OvBF3NPJf5CR3jompv7tlmYHTXtqoHVLc7BbFYsKfopW5wOOvlbD_iuv012w2b1v4YbVxZs-Tyy9qlchUU9czDkTBVEbfHF6qA6C5MD7TtHOS0bKdgrt-xH-plu-JC7v5O4-XBU-Y90BT-UOpnRXOCnFzGKt_NlNkbrbNJq3hHoA2kuf-oiuG3b7nLDuCpwqu38THBkbMXgAcAMEMRafIWiTItHwDuilRoB0`,
                Accept: 'application/json',
                'Content-Type': 'application/json',
            };

            const response = await axios.post(`${process.env.REACT_APP_API_URL}appointment`, { ...params, ...props.match.params }, { headers });
            const data = await response.data;

            if (data) {

                message.success('Appointment Booked Successfully')
                form.resetFields()

                console.table({ ...params, ...props.match.params })

                const headers = {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                }

                await axios.post(`${process.env.REACT_APP_NODE_API_URL}/appointment`, { psid: props.match.params.psid }, { headers })

            }

        } catch (error) {

            if (error.message === 'Request failed with status code 422') {

                message.error('The psid has already been taken')

            } else {

                message.error(error.message)

            }




        }

    }

    const onSelectBranch = e => selectBranch(e.target.value)
    const onDate = () => { }
    const onTime = () => { }
    const disabledDate = (current) => current && current < moment().endOf('day')

    return (
        <main className='appointment-form'>
            <Form
                form={form}
                name="form-appointment"
                onFinish={onSubmit}
                hideRequiredMark
                scrollToFirstError
                layout="vertical"
            >  <h1>Appointment Form</h1>

                <Form.Item
                    label='Mobile Number'
                    name={["mobile_number"]}
                    rules={[{ required: true, message: "Please input your mobile number" }]}
                >
                    <Input type='number' onKeyPressCapture={(e) => {
                        const specialCharRegex = new RegExp(/^[0-9a-zA-Z]+$/);
                        const pressedKey = String.fromCharCode(!e.charCode ? e.which : e.charCode);

                        if (!specialCharRegex.test(pressedKey)) {
                            e.preventDefault();
                            return false;
                        }
                    }} />

                </Form.Item>

                <Form.Item
                    label='Date'
                    name={["date"]}
                    rules={[{ required: true, message: "Please input date" }]}
                >

                    <DatePicker disabledDate={disabledDate}
                        onChange={onDate}
                        allowEmpty
                        size='middle'
                        className='date-created'
                    />

                </Form.Item>
                <Form.Item
                    label='Time'
                    name={["time"]}
                    rules={[{ required: true, message: "Please input time" }]}
                >

                    <TimePicker onChange={onTime} use12Hours order={false} />
                    {/* 
                    <Form.Item
                  name="startend"
                  label="Start Time to End Time"
                  rules={[
                    {
                      required: isRestDay ? false : true,
                      message:
                        "Please input range between start time to end time",
                    },
                  ]}
                >
                  <TimePicker.RangePicker use12Hours order={false} />
                </Form.Item> */}
                </Form.Item>


                <Form.Item
                    label='Branch'
                    name={["branch"]}
                    rules={[{ required: true, message: "Please input branch" }]}
                >


                    <Radio.Group onChange={onSelectBranch} value={branch}>
                        <Radio value={1}>San Pedro</Radio>
                        <Radio value={2}>Rizal</Radio>
                    </Radio.Group>

                </Form.Item>


                <Button className='btn-primary' htmlType="submit" block>Submit</Button>
            </Form>
        </main >
    )
}
