import React, { useEffect, memo } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { Form, Input, Button, message } from "antd";
import "./index.scss";
import { login } from '../../actions';

export default memo(function Login() {

    const loginDispatch = useDispatch();

    const isLoginRejected = useSelector(state => state.auth.isLoginRejected);

    useEffect(() => {
        isLoginRejected && message.error("Incorrect Email/Password");
    }, [isLoginRejected]);


    const onSubmit = (credentials) => {
        loginDispatch(login({ ...credentials }))
    }

    const [form] = Form.useForm();

    return (
        <main className='login'>
            <Form
                form={form}
                name="form-login"
                onFinish={onSubmit}
                hideRequiredMark
                scrollToFirstError
            >  <div className='logo' />
                <Form.Item
                    name={["email"]}
                    rules={[{ required: true, message: "Please input your email" }]}
                >
                    <Input placeholder="Email" autoFocus />
                </Form.Item>
                <Form.Item
                    name={["password"]}
                    rules={[
                        {
                            required: true, message: "Please input your password"
                        },
                        {
                            min: 8,
                            message: "Password must be minimum of 8 characters",
                        }
                    ]}
                >
                    <Input.Password placeholder="Password" />
                </Form.Item>

                <Button className='btn-primary' htmlType="submit" block>log in</Button>
                <a href='/'>Forgot password?</a>
            </Form>
        </main>
    )
})